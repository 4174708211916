import { useQuery } from '@tanstack/react-query'

import { api, type APIRoutes, type APISchemas } from './api'

async function createMainPlan(
  body: APIRoutes['/MainPlanner']['post']['requestBody']
): Promise<APISchemas['SavedList']> {
  return api.post('MainPlanner', { json: body }).json<APISchemas['SavedList']>()
}

export function useCreateMainPlan(body: APIRoutes['/MainPlanner']['post']['requestBody']) {
  return useQuery(['MainPlanner', 'post', body], () => createMainPlan(body))
}

function getAllPlans(
  searchParams: APIRoutes['/Plan']['get']['parameters']['query']
): Promise<APISchemas['CoursePlanPageResults']> {
  return api.get('Plan', { searchParams }).json<APISchemas['CoursePlanPageResults']>()
}

export function usePlans(searchParams: APIRoutes['/Plan']['get']['parameters']['query'] = {}) {
  const { data, isLoading } = useQuery(['Plan', 'get'], () => getAllPlans(searchParams))

  return { data: data?.entries, isLoading }
}

function getPlan(
  params: APIRoutes['/Plan/{plannerid}']['get']['parameters']['path']
): Promise<APISchemas['SavedList']> {
  return api.get(`Plan/${params.plannerid}`).json<APISchemas['SavedList']>()
}

export function usePlan(params: APIRoutes['/Plan/{plannerid}']['get']['parameters']['path']) {
  return useQuery(['Plan/{plannerid}', 'get', params], () => getPlan(params))
}

function getMainPlan(
  params: APIRoutes['/MainPlanner/{sunetId}']['get']['parameters']['path']
): Promise<APISchemas['SavedList']> {
  return api.get(`MainPlanner/${params.sunetId}`).json<APISchemas['SavedList']>()
}

export function useMainPlan(params: APIRoutes['/MainPlanner/{sunetId}']['get']['parameters']['path']) {
  return useQuery(['MainPlanner/{sunetId}', 'get', params], () => getMainPlan(params))
}

async function editPlan(
  params: APIRoutes['/Plan/{plannerid}/Courses']['post']['parameters']['path'],
  body?: APIRoutes['/Plan/{plannerid}/Courses']['post']['requestBody']
): Promise<APISchemas['CourseInPlan']> {
  try {
    const response = await api
      .post(`Plan/${params.plannerid}/Courses`, { json: body })
      .json<APISchemas['CourseInPlan']>()

    return response
  } catch (error: any) {
    if (error.response?.status === 404) {
      console.error('Plan not found', error.response?.data)
      throw new Error('Plan not found')
    }

    throw error
  }
}

export function useEditPlan(
  params: APIRoutes['/Plan/{plannerid}/Courses']['post']['parameters']['path'],
  body?: APIRoutes['/Plan/{plannerid}/Courses']['post']['requestBody']
) {
  return useQuery(['Plan/{plannerid}/Courses', 'post', params, body], () => editPlan(params, body))
}

async function editMainPlan(
  params: APIRoutes['/MainPlanner/{sunetId}/Courses']['post']['parameters']['path'],
  body?: APISchemas['SavedListAddCourseRequest']
): Promise<APISchemas['SavedCourse']> {
  try {
    const response = await api
      .post(`MainPlanner/${params.sunetId}/Courses`, { json: body })
      .json<APISchemas['SavedCourse']>()

    return response
  } catch (error: any) {
    if (error.response?.status === 404) {
      console.error('Plan not found', error.response?.data)
      throw new Error('Plan not found')
    }

    throw error
  }
}

export function useEditMainPlan(
  params: APIRoutes['/MainPlanner/{sunetId}/Courses']['post']['parameters']['path'],
  body?: APISchemas['SavedListAddCourseRequest']
) {
  return useQuery(['MainPlanner/{sunetId}/Courses', 'post', params, body], () =>
    editMainPlan(params, body)
  )
}
