import { type FunctionComponent } from 'react'
import { getCourseTitle, getCourseUnits, STATIC_SIDEBAR_WIDTH } from 'src/routes/Home/Sidebar'
import { truncateMiddle } from 'src/utils/truncateMiddle'
import styled from 'styled-components'

import { CuiFlexGroup } from '../CuiFlexGroup'
import { CuiIcon } from '../CuiIcon'
import { CuiText } from '../CuiText'

type PinnedProps = { isMajor: boolean; courses: any[] }

// const Cell = styled.div<{ width?: number; height?: number; border?: string }>`
//   width: ${({ width }) => width}px;
//   height: ${({ height }) => height}px;
//   border: ${({ border }) => border};
//   border-color: ${({ theme }) => theme.cuiColors.accent};
//   box-sizing: border-box;
//   margin-left: -1px;
//   margin-bottom: -1px;
//   text-align: center;
// `

const MAX_COURSE_TITLE = 14

const CourseRoot = styled.div`
  display: flex;
  flex-direction: column;
  width: ${STATIC_SIDEBAR_WIDTH};
  padding: 4px 6px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.cuiColors.background};
`

const Course = styled.div<{ isMajor: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 96%;
  padding: 4px 6px;
  border-radius: 4px;
  border: ${({ theme, isMajor }) =>
    isMajor
      ? `0.62px solid ${theme.cuiColors.darkShade}`
      : `0.5px solid ${theme.cuiColors.lightestShade}`};
  border-left: 5px solid
    ${({ theme, isMajor }) => (isMajor ? theme.cuiColors.darkAccent : theme.cuiColors.darkestShade)};
  box-shadow: ${({ theme, isMajor }) =>
    isMajor ? 'none' : `0px 0px 16px ${theme.cuiColors.boxShadow}`};
`
export const CuiPinnedCourses: FunctionComponent<PinnedProps> = ({ isMajor, courses }) => {
  return (
    <CuiFlexGroup direction='column' alignItems='flexStart' gutterSizeVertical='xs'>
      <CuiText size='title3'>&nbsp; &nbsp; {isMajor ? 'Spring 2023' : 'Courses'}</CuiText>
      {courses.map((course) => (
        <CourseRoot key={course.metaOffering.id}>
          <Course isMajor={isMajor}>
            <CuiFlexGroup gutterSizeHorizontal='xl' justifyContent='spaceBetween'>
              <CuiFlexGroup direction='row' gutterSizeHorizontal='s'>
                <CuiText size='title4'>{getCourseTitle(course.metaOffering.courseCode)}</CuiText>
                <CuiText size='title4'>
                  {truncateMiddle(course.metaOffering.title, MAX_COURSE_TITLE)}
                </CuiText>
              </CuiFlexGroup>
            </CuiFlexGroup>
            <CuiFlexGroup gutterSizeHorizontal='s'>
              <CuiText size='title4'>
                {getCourseUnits(course.metaOffering.units.minimum, course.metaOffering.units.maximum)}{' '}
              </CuiText>
              <CuiIcon type={!isMajor ? 'close' : 'check'} />
            </CuiFlexGroup>
          </Course>
        </CourseRoot>
      ))}
    </CuiFlexGroup>
  )
}
