import { type FunctionComponent, type PropsWithChildren, useEffect, useState } from 'react'
import { type APISchemas } from 'src/api/api'
import { useCourseSchedules } from 'src/api/course'
import { useMainPlan } from 'src/api/plan'
import {
  CuiButton,
  CuiCalendar,
  CuiFlexGroup,
  CuiPad,
  CuiPinnedCourses,
  CuiSpacer,
  CuiText,
} from 'src/cui/components'
import { ReactComponent as CartaLogo } from 'src/images/logos/logo.svg'
import { ticksToHours } from 'src/utils/ticksToHours'
import styled from 'styled-components'

export const STATIC_SIDEBAR_WIDTH = '320px'
export const STATIC_SIDEBAR_PADDING = '24px'

const fakeData = [
  {
    id: '3126a099-5c9d-4885-969e-37cc95d6f066',
    metaOffering: {
      id: '2025:2852496b73ee4b1abd73b6bb3f5466cf:CS100ACE',
      title: 'Problem-solving Lab for CS106A',
      description:
        'Additional problem solving practice for the introductory CS course CS 106A. Sections are designed to allow students to acquire a deeper understanding of CS and its applications, work collaboratively, and develop a mastery of the material. Limited enrollment, permission of instructor required. Concurrent enrollment in CS 106A required.',
      courseCode: {
        subject: 'CS',
        catalogNumber: '100ACE',
      },
      crosslistings: [],
      repeatability: {
        maxRepeatCount: 1,
        maxRepeatUnits: null,
      },
      units: {
        minimum: 1,
        maximum: 1,
      },
      attendance: {
        isUnusuallyVarying: false,
        value: 'InPerson',
      },
      requirements: {
        isUnusuallyVarying: false,
        value: [],
      },
      seasons: ['Autumn', 'Winter', 'Spring'],
      gradingBasis: {
        isUnusuallyVarying: false,
        value: 'SatisfactoryNC',
      },
    },
    termAddedId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
  },
]

type CuiCalendarHeaderProps = {
  /**
   *
   * quarter to display
   *
   * @default 0
   */
  quarter: number

  /**
   *
   * year to display
   *
   * @default 0
   */
  year: number

  /**
   *
   * set quarter
   *
   *
   */
  setQuarter: (quarter: number) => void

  /**
   *
   * set year
   *
   *
   */
  setYear: (year: number) => void
}

export type CuiCalendarDataProps = {
  /**
   *
   * course ID
   *
   *
   */
  courseId: string

  /**
   *
   * quarter to display
   *
   * @default 'Autumn'
   */
  currentQuarter: string

  /**
   *
   * year to display
   *
   * @default 2024
   */
  endYear: number
}

export const SidebarRoot = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: ${STATIC_SIDEBAR_WIDTH};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 ${STATIC_SIDEBAR_PADDING};
  background-color: ${({ theme }) => theme.cuiColors.background};
`

export const TitleComponent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  padding: 12px 28px;
  padding-left: ${STATIC_SIDEBAR_PADDING};
`

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

// function useCalendarCourses(season: string, endYear: number) {
//   const { data } = useMainPlan({ sunetId: 'hhannah' })

//   const filteredData = data?.courses.filter(
//     (course) => course.term.season === season && course.term.endYear === endYear
//   )

//   return filteredData
// }

function useCalendarCourses() {
  const { data } = useMainPlan({ sunetId: 'hhannah' })

  return data?.courses ?? []
}

const DAY_TO_INDEX: { [key: string]: number } = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
}

const CALENDAR_START_HOUR = 8

const quarters = ['Autumn', 'Winter', 'Spring', 'Summer']
const date = new Date()
const currentYear = date.getFullYear()
const currentMonth = date.getMonth()

function getCurrentQuarter(currentMonth: number) {
  if (currentMonth <= 2) {
    return 1
  } else if (currentMonth <= 5) {
    return 2
  } else if (currentMonth <= 7) {
    return 3
  }

  return 0
}

function getCurrentSchoolYear(currentMonth: number) {
  if (currentMonth <= 7) {
    return currentYear - 1
  }

  return currentYear
}

const CuiCalendarHeader: FunctionComponent<PropsWithChildren<CuiCalendarHeaderProps>> = ({
  quarter,
  year,
  setQuarter,
  setYear,
}) => {
  const handleChevronClick = (direction: 'left' | 'right') => {
    if (direction === 'left') {
      setQuarter(quarter === 0 ? 3 : quarter - 1)
      if (quarter === 0) {
        setYear(year === 0 ? year - 1 : year - 1)
      }
    } else {
      setQuarter(quarter === 3 ? 0 : quarter + 1)
      if (quarter === 3) {
        setYear(year === year - 1 ? 0 : year + 1)
      }
    }
  }

  const title = `${quarters[quarter]} ${year}-${(year + 1) % 2000}`

  return (
    <HeaderTitle>
      <CuiButton iconType='chevron-left' onClick={() => handleChevronClick('left')} />
      <CuiText size='title2'>{title}</CuiText>
      <CuiButton iconType='chevron-right' onClick={() => handleChevronClick('right')} />
    </HeaderTitle>
  )
}

export const getCourseTitle = (courseCode: { subject: string; catalogNumber: string }) => {
  if (!courseCode) {
    return ''
  }

  if (courseCode.catalogNumber.length > 3) {
    return `${courseCode.subject}...${courseCode.catalogNumber.slice(-3)}`
  }

  const courseTitle = `${courseCode.subject} ${courseCode.catalogNumber.slice(-3)}`

  return courseTitle
}

export const getCourseUnits = (minimum: number, maximum: number) => {
  let units = `${minimum}`

  if (minimum !== maximum) {
    units = `${minimum}-${maximum}`
  }

  if (Number(units) === 1) {
    return `${units} unit`
  }

  return `${units} units`
}

const GetCourseStart = (props: CuiCalendarDataProps) => {
  const { courseId, currentQuarter, endYear } = props
  const schedule = useCourseSchedules({ id: courseId })
  let scheduleData = schedule.data?.entries as unknown as APISchemas['Schedules'][]
  if (!scheduleData) {
    return 0
  }

  scheduleData = scheduleData.filter(
    (entry) =>
      entry.term.season === currentQuarter &&
      entry.term.endYear === endYear &&
      entry.section.component === 'Lecture'
  )

  if (scheduleData.length === 0) {
    return 0
  }

  const time = ticksToHours(scheduleData[0].meetingTime.lowerBound.tickOfDay)

  return time - CALENDAR_START_HOUR
}

const GetCourseDuration = (props: CuiCalendarDataProps) => {
  const { courseId, currentQuarter, endYear } = props
  const schedule = useCourseSchedules({ id: courseId })
  let scheduleData = schedule.data?.entries as unknown as APISchemas['Schedules'][]
  if (!scheduleData) {
    return 0
  }

  scheduleData = scheduleData.filter(
    (entry) =>
      entry.term.season === currentQuarter &&
      entry.term.endYear === endYear &&
      entry.section.component === 'Lecture'
  )

  if (scheduleData.length === 0) {
    return 0
  }

  const duration =
    ticksToHours(scheduleData[0].meetingTime.upperBound.tickOfDay) -
    ticksToHours(scheduleData[0].meetingTime.lowerBound.tickOfDay)

  return duration
}

const GetCourseDay = (props: CuiCalendarDataProps): number[] => {
  const { courseId, currentQuarter, endYear } = props
  const schedule = useCourseSchedules({ id: courseId })
  let scheduleData = schedule.data?.entries as unknown as APISchemas['Schedules'][]
  if (!scheduleData) {
    return [1]
  }

  scheduleData = scheduleData.filter(
    (entry) =>
      entry.term.season === currentQuarter &&
      entry.term.endYear === endYear &&
      entry.section.component === 'Lecture'
  )
  if (scheduleData.length === 0) {
    return [1]
  }

  return scheduleData[0].meetingDays.map((day) => DAY_TO_INDEX[day])
}

export const Sidebar: FunctionComponent<PropsWithChildren<unknown>> = () => {
  const data = useCalendarCourses() ?? fakeData
  const [quarter, setQuarter] = useState(getCurrentQuarter(currentMonth))
  const [year, setYear] = useState(getCurrentSchoolYear(currentMonth))

  useEffect(() => {
    localStorage.setItem('quarter', quarter.toString())
    localStorage.setItem('endYear', year.toString())
  }, [quarter, year])

  return (
    <SidebarRoot>
      <TitleComponent>
        <CartaLogo />
      </TitleComponent>
      <CuiSpacer size='m' />
      <CuiFlexGroup alignItems='center' direction='column'>
        <CuiCalendarHeader quarter={quarter} setQuarter={setQuarter} year={year} setYear={setYear} />
        <CuiSpacer as='hr' size='s' />
        <div style={{ alignSelf: 'flex-start' }}>
          <CuiText size='title3'>&nbsp; Planned schedule</CuiText>
        </div>
        <CuiPad verticalSize='xs' />
        <CuiCalendar
          data={data ?? []}
          getCourseTitle={getCourseTitle}
          getCourseStart={GetCourseStart}
          getCourseDuration={GetCourseDuration}
          getCourseDay={GetCourseDay}
          width={52}
          height={38}
          rows={10}
          currentQuarter={quarters[quarter]}
          endYear={year}
        />
        <CuiPad size='s' />
        <CuiPinnedCourses isMajor={false} courses={data} />
      </CuiFlexGroup>
    </SidebarRoot>
  )
}
